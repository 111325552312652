import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "breadcrumb",
      "style": {
        "position": "relative"
      }
    }}>{`Breadcrumb`}</h1>
    <p>{`Breadcrumbs enable users to quickly see their location within a path of navigation and move up to a parent level if desired.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Place Breadcrumbs near the top of the page.`}</li>
      <li parentName="ul">{`Show hierarchy, not history.`}</li>
      <li parentName="ul">{`Keep Breadcrumb titles short but descriptive.`}</li>
      <li parentName="ul">{`Do not use a Breadcrumb if you are taking users through a multi-step process.`}</li>
      <li parentName="ul">{`Use Breadcrumbs only if there are two or more navigation levels (ex.: Home > Settings).`}</li>
    </ul>
    <Demo src='pages/components/breadcrumb/Breadcrumb' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      